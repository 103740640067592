import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import TitleBar from "../../components/uiParts/TitleBar";
import EntryCustomInput from '../../components/entryForm/EntryCustomInput'
import EntryCustomField from '../../components/entryForm/EntryCustomField'
import EntryCustomSelect from '../../components/entryForm/EntryCustomSelect'
import {
    Grid,
    Box,
    useMediaQuery,
    useTheme,
    Button,
    FormLabel,
    Stack,
    TextField,
    FormHelperText,
    FormControl,
    FormControlLabel,
    Checkbox,
    Typography,
} from "@mui/material";
import { useFetch } from "../../api/ApiRequests";
import { apiURL } from "../../api/ApiConfig";
import { certificationList, cityList } from "../../Store";
import { usePost } from "../../api/ApiRequests";
import { useAuth } from "../../contexts/AuthContext";
import { useForm, Controller, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";

const SellerMeetingReportPage = ({ formData, setFormData }) => {
    const { authInfo, setAuthInfo } = useAuth();
    const navigate = useNavigate();
    const { id: meetingId } = useParams();
    const { data: meetingData, fetchData: fetchMeetingData } = useFetch();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    //===============================================
    // initialize
    //===============================================
    const systemName = process.env.REACT_APP_SYSTEM_NAME;

    const [isConfirm, setIsConfirm] = useState(false)


    const certificationCheckFields = (data) => {
        const checkFields = {};
        for (let i = 9; i <= 20; i++) {
            const key = `checkField${String(i).padStart(2, '0')}`;
            if (key in data) {
                checkFields[key] = data[key];
            }
        }
        return checkFields;
    };

    //-----------------------------------------------
    // initialize - セッション情報を取得
    //-----------------------------------------------
    const userId = authInfo.userId;
    const userName = authInfo.userName;
    const authority = authInfo.authority;

    const effectRan = useRef(false);

    const title = `出展者ユーザ：${userName}`;

    //===============================================
    // API
    //===============================================

    useEffect(() => {
        if (!authInfo.sellerId) return;
        fetchMeetingData(`/mst/seller/meeting/${meetingId}`);
    }, [authInfo]);
    //-----------------------------------------------
    // API - POSTメソッドのテンプレート
    //-----------------------------------------------
    const { postData } = usePost({
        userId: userId,
        userName: userName,
    });

    //===============================================
    // useEffect
    //===============================================
    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, []);

    useEffect(() => {
        // getData()
    }, []);

    //===============================================
    // eventHandler
    //===============================================
    const handleClick_Edit = () => {
        navigate("/seller/profile/edit");
    };

    const initialData = {
        checkField01: false,
        checkField02: false,
        checkField03: false,
        textField01: "",
        textField02: "",
    }

    const onSubmit = async (data) => {
        setFormData(data);
        if (isConfirm) {
            try {
                await postData(`/mst/meetingResult/`, {
                    ...data, meeting_id: parseInt(meetingId)
                })
            } catch (e) {
                console.error(e)
            }
            navigate(`/seller/meetings/${meetingId}/done`);
        } else {
            setIsConfirm(true)
        }
    }

    const resultText = formData?.checkField01 ? "成立" : formData?.checkField02 ? "不成立" : "保留 ・ 検討"

    //===============================================
    // フォーム
    //===============================================
    //-----------------------------------------------
    // フォーム - スキーマ定義
    //-----------------------------------------------
    const schema = yup.object().shape({
        checkField01: yup.boolean(),
        checkField02: yup.boolean(),
        checkField03: yup.boolean(),
        checkTarget: yup.boolean()
            .test(
                'searchTest',
                '商談結果を一つ選択してください',
                function (_) {
                    const count = [this.parent.checkField01, this.parent.checkField02, this.parent.checkField03]?.filter(v => v).length
                    console.log([this.parent.checkField01 || this.parent.checkField02 || this.parent.checkField03]?.filter(v => v).length)
                    return count === 1
                }
            ),
        textField01: yup.string().required('商談した商品名を入力してください'),
        textField02: yup.string().required('商談結果の内容を入力してください'),
    })
    const useFormMethods = useForm({
        defaultValues: initialData,
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        resolver: yupResolver(schema),
    });
    const { control, handleSubmit, formState: { errors }, setValue, reset } = useFormMethods;

    //===============================================
    // CSS
    //===============================================
    const homeContainer = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
        backgroundColor: "#F0F0F1",
        padding: "0 10px",
        flexGrow: "1",
    };
    const homeInnerContainer = {
        flexDirection: "column",
        maxWidth: "800px",
        width: "100%",
        overflow: "hidden",
        alignItems: "center",
        justifyContent: "center",
    };
    const formContainer = {
        marginTop: "30px",
        paddingTop: "15px",
        paddingBottom: "20px",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        // padding: "0 20px",
    };
    const tableStyle = {
        borderCollapse: 'collapse',
        width: "90%",
        maxWidth: "550px",
    };

    const tableHeaderStyle = {
        border: '1px solid #909090',
        padding: '8px',
        textAlign: "start",
        fontWeight: "normal",
        backgroundColor: "white",
        width: "100px",
    };

    const tableCellStyle = {
        border: '1px solid #909090',
        padding: '8px',
        textAlign: "start",
        fontWeight: "normal",
        backgroundColor: "white",
        // minWidth: "200px",
    };

    return (
        <div style={{ height: "100%" }}>
            <Header
                title={systemName}
                loginInfo={isSmallScreen ? "off" : "on"}
                backButton={isSmallScreen ? "off" : "on"}
            />
            <div style={{}}>
                <TitleBar title={title} />
            </div>
            <FormProvider  {...useFormMethods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div style={homeContainer}>
                        <div style={homeInnerContainer}>
                            <div
                                style={{
                                    backgroundColor: "#D5EDC7",
                                    padding: "15px",
                                    borderRadius: "10px",
                                    textAlign: "center",
                                    marginTop: "20px"
                                }}
                            >
                                オンライン商談が終わったら結果報告入力をして、 「入力情報の確認をする」 をクリックしてください。
                            </div>
                            <div className="form-container" style={formContainer}>
                                <div style={{ position: "relative" }}>
                                    <div
                                        style={{
                                            textAlign: "center",
                                            fontSize: "1.5em",
                                            margin: "0.5em",
                                            fontWeight: "bold",
                                            borderBottom: "1px solid",
                                        }}
                                    >
                                        オンライン商談結果報告
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

                                    <table style={tableStyle}>
                                        <tbody>
                                            <tr>
                                                <th style={{ ...tableHeaderStyle, textAlign: "center", backgroundColor: "#F1F1F1" }}>番号</th>
                                                <td style={tableCellStyle}>{meetingData?.serialNo && `${meetingData.serialNo}`.padStart(3, "0")}</td>
                                            </tr>
                                            <tr>
                                                <th style={{ ...tableHeaderStyle, textAlign: "center", backgroundColor: "#F1F1F1" }}>日程調整日</th>
                                                <td style={{ ...tableCellStyle, backgroundColor: "white" }}>{meetingData?.adjustmentDate}</td>
                                            </tr>
                                            <tr>
                                                <th style={{ ...tableHeaderStyle, textAlign: "center", backgroundColor: "#F1F1F1" }}>出展者名</th>
                                                <td style={tableCellStyle}>{meetingData?.sellerName}</td>
                                            </tr>
                                            <tr>
                                                <th style={{ ...tableHeaderStyle, textAlign: "center", backgroundColor: "#F1F1F1" }}>商品名</th>
                                                <td style={tableCellStyle}>{meetingData?.itemName}</td>
                                            </tr>
                                            <tr>
                                                <th style={{ ...tableHeaderStyle, textAlign: "center", backgroundColor: "#F1F1F1" }}>商談日時</th>
                                                <td style={tableCellStyle}>{meetingData?.meetingDate}</td>
                                            </tr>
                                        </tbody>
                                    </table >

                                    {
                                        isConfirm ? <>
                                            <Stack spacing={2} style={{ maxWidth: "550px", marginTop: "1em" }} justifyContent={"center"}>
                                                <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start'>
                                                    <Typography sx={{ minWidth: '200px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>商談結果</Typography>
                                                    <Typography sx={{ mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{resultText}</Typography>
                                                </Stack>
                                                <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start'>
                                                    <Typography sx={{ minWidth: '200px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>商談した商品名</Typography>
                                                    <Typography sx={{ mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{formData.textField01}</Typography>
                                                </Stack>
                                                <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start'>
                                                    <Typography sx={{ minWidth: '200px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>商談結果の内容</Typography>
                                                    <Typography sx={{ mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{formData.textField02}</Typography>
                                                </Stack>
                                            </Stack></> :
                                            <Grid item xs={12} style={{ margin: "1em 0" }}>
                                                <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2}>
                                                    <Grid item sx={{ width: '250px', textAlign: 'left', mt: 1 }}>
                                                        <FormLabel sx={{ whiteSpace: 'nowrap', border: '1px solid red', color: 'red', padding: '5px' }}>必須</FormLabel>
                                                        <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold' }}>商談結果</FormLabel>
                                                    </Grid>
                                                    <Grid item xs={isSmallScreen ? 12 : "auto"}>
                                                        <Stack direction={isSmallScreen ? "column" : "row"}>
                                                            <Grid >
                                                                <FormControl fullWidth>
                                                                    <Controller
                                                                        name='checkField01'
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Checkbox
                                                                                        {...field}
                                                                                        checked={field.value}
                                                                                        name='checkField01'
                                                                                        onChange={(e) => {
                                                                                            field.onChange(e);
                                                                                        }}
                                                                                    />
                                                                                }
                                                                                label='成立'
                                                                                sx={{ color: '#666666', whiteSpace: 'nowrap', width: '90px' }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid >
                                                                <FormControl fullWidth>
                                                                    <Controller
                                                                        name='checkField02'
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Checkbox
                                                                                        {...field}
                                                                                        checked={field.value}
                                                                                        name='checkField02'
                                                                                        onChange={(e) => {
                                                                                            field.onChange(e);
                                                                                        }}
                                                                                    />
                                                                                }
                                                                                label='不成立'
                                                                                sx={{ color: '#666666', whiteSpace: 'nowrap', width: '90px' }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid>
                                                                <FormControl fullWidth>
                                                                    <Controller
                                                                        name='checkField03'
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Checkbox
                                                                                        {...field}
                                                                                        checked={field.value}
                                                                                        name='checkField03'
                                                                                        onChange={(e) => {
                                                                                            field.onChange(e);
                                                                                        }}
                                                                                    />
                                                                                }
                                                                                label='保留 ・ 検討'
                                                                                sx={{ color: '#666666', whiteSpace: 'nowrap', width: '90px' }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                        </Stack>
                                                        {errors.checkTarget && <div style={{ width: "100%", textAlign: "start", paddingLeft: "14px", color: '#d32f2f', fontSize: '0.8em' }}>{errors.checkTarget.message}</div>}
                                                    </Grid>
                                                </Grid>
                                                <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2}>
                                                    <EntryCustomInput
                                                        id='textField01' name='textField01'
                                                        gideFlag
                                                        label='商談した商品名' labelWidth='250px' required='on' placeHolder="商品名を記載してください。"
                                                    />
                                                </Grid>
                                                <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2}>
                                                    <EntryCustomField
                                                        hideHelperText
                                                        id='textField02' name='textField02'
                                                        label='商談結果の内容' labelWidth='250px' required='on'
                                                        placeHolder="『○○○○ 720ml』 をアピールすることができ、
                                                                    バイヤーは 『○○○○』 の品質に強い興味を示してくれました。
                                                                    しかし 『○○○○○○』 との意見もいただきました。
                                                                    金額面から数量○○本を販売時期は 「○○」 ~「○○」 の期間で成立することができました。"
                                                    />
                                                </Grid>
                                            </Grid>
                                    }
                                </div>
                            </div>
                        </div>
                        <Grid item xs={12} sx={{ my: 3 }}>
                            <Button
                                onClick={() => {
                                    isConfirm ? setIsConfirm(false) : navigate(-1)
                                }}
                                variant="outlined"
                                sx={{ mr: 1 }}
                                size="large"
                                style={{
                                    color: "#4BBC06",
                                    fontSize: "20px",
                                    width: "300px",
                                    margin: "1rem",
                                }}
                            >
                                戻　る
                            </Button>
                            <Button
                                type="submit" variant="contained" sx={{ mr: 1 }} size="large"
                                style={{ color: '#fff', backgroundColor: '#4BBC06', fontSize: '20px', width: '300px', margin: '0.5rem' }}
                            >
                                {isConfirm ? "結果を報告する" : " 入力情報の確認をする"}
                            </Button>
                        </Grid>
                    </div>
                </form>
            </FormProvider>
            <Footer />
        </div >
    );
};

export default SellerMeetingReportPage;
