
export function getReviewLabel(fieldName) {
    const fieldNumber = parseInt(fieldName.replace('checkField', ''), 10);
    // 商談成立可否
    if (fieldNumber < 4) {
        switch (fieldNumber) {
            case 1:
                return "成立";
            case 2:
                return "不成立";
            case 3:
                return "保留・検討";
            default:
                return "";
        }
    }
    // それ以外
    const remainder = (fieldNumber - 4) % 5;
    switch (remainder) {
        case 0:
            return "満足";
        case 1:
            return "やや満足";
        case 2:
            return "普通";
        case 3:
            return "やや不満";
        case 4:
            return "不満";
        default:
            return "";
    }
}