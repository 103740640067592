export function InstructionModal({ onClickClose, data }) {

  const onClickManual = (path) => {
    path && window.open(path, "_blank")
  }

  return (
    <div>
      <div
        onClick={() => onClickClose()}
        style={{
          position: "fixed",
          top: 0,
          width: "100vw",
          height: "100vh",
          background: "rgba(0,0,0,0.5)",
          zIndex: 100,
        }}
      />
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "500px",
          width: "90%",
          margin: 0,
          zIndex: 101,
          background: "white",
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        <div
          style={{
            width: "100%",
            background: "rgb(0, 125, 80)",
            borderRadius: "5px",
            color: "white",
            paddingLeft: "2rem",
            textAlign: "left",
            paddingTop: "5px",
            paddingBottom: "5px",
          }}
        >
          操作説明書
        </div>
        <p>閲覧したい操作説明書(マニュアル)を選択してください。</p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            color: "white",
            gap: "1rem",
            paddingTop: "2rem",
            paddingBottom: "4rem",
          }}
        >
          {
            data.map(d =>
              <div
                style={{
                  background: "rgb(75, 188, 6)",
                  padding: "10px 20px",
                  width: "45%",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={() => onClickManual(d?.path)}
              >
                {d.title}
              </div>)
          }
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            color: "white",
            gap: "1rem",
          }}
        >
          <div
            style={{
              background: "gray",
              padding: "10px 20px",
              width: "40%",
              borderRadius: "5px",
              cursor: "pointer",
              color: "white",
            }}
            onClick={() => onClickClose()}
          >
            閉じる
          </div>
        </div>
      </div>
    </div>
  );
}
