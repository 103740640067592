import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import TitleBar from "../../components/uiParts/TitleBar";
import EntryCustomInput from '../../components/entryForm/EntryCustomInput'
import EntryCustomField from '../../components/entryForm/EntryCustomField'
import EntryCustomSelect from '../../components/entryForm/EntryCustomSelect'
import {
    Grid,
    Box,
    useMediaQuery,
    useTheme,
    Button,
    FormLabel,
    Stack,
    TextField,
    FormHelperText,
    FormControl,
    FormControlLabel,
    Checkbox,
    Typography,
    RadioGroup,
    Radio,
} from "@mui/material";
import { useFetch } from "../../api/ApiRequests";
import { apiURL } from "../../api/ApiConfig";
import { certificationList, cityList } from "../../Store";
import { usePost } from "../../api/ApiRequests";
import { useAuth } from "../../contexts/AuthContext";
import { useForm, Controller, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { getReviewLabel } from "../../utils/getReviewLabel";

const BuyerReviewPage = ({ formData, setFormData }) => {
    const { authInfo, setAuthInfo } = useAuth();
    const navigate = useNavigate();
    const { id: meetingId } = useParams();
    const { data: meetingData, fetchData: fetchMeetingData } = useFetch();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    //===============================================
    // initialize
    //===============================================
    const systemName = process.env.REACT_APP_SYSTEM_NAME;

    const [isConfirm, setIsConfirm] = useState(false)


    const certificationCheckFields = (data) => {
        const checkFields = {};
        for (let i = 9; i <= 20; i++) {
            const key = `checkField${String(i).padStart(2, '0')}`;
            if (key in data) {
                checkFields[key] = data[key];
            }
        }
        return checkFields;
    };

    //-----------------------------------------------
    // initialize - セッション情報を取得
    //-----------------------------------------------
    const userId = authInfo.userId;
    const userName = authInfo.userName;
    const authority = authInfo.authority;

    const effectRan = useRef(false);

    const title = `レビュー投稿`;

    //===============================================
    // API
    //===============================================

    useEffect(() => {
        if (!authInfo.buyerId) return;
        fetchMeetingData(`/mst/buyer/meeting/${meetingId}`);
    }, [authInfo]);
    //-----------------------------------------------
    // API - POSTメソッドのテンプレート
    //-----------------------------------------------
    const { postData } = usePost({
        userId: userId,
        userName: userName,
    });

    //===============================================
    // useEffect
    //===============================================
    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, []);

    useEffect(() => {
        // getData()
    }, []);

    //===============================================
    // eventHandler
    //===============================================
    const handleClick_Edit = () => {
        navigate("/seller/profile/edit");
    };

    const initialData = {
        checkField01: false,
        checkField02: false,
        checkField03: false,
        textField01: "",
        textField02: "",
    }


    const trueLabels = Object.entries(formData).filter(([k, v]) => v === true)
    const labels = trueLabels.map(l => getReviewLabel(l[0]))

    const onSubmit = async (data) => {
        const convertedData = {
            textField01: data.textField01,
            textField02: data.textField02,
            checkField01: false,
            checkField02: false,
            checkField03: false,
            checkField04: false,
            checkField05: false,
            checkField06: false,
            checkField07: false,
            checkField08: false,
            checkField09: false,
            checkField10: false,
            checkField11: false,
            checkField12: false,
            checkField13: false,
            checkField14: false,
            checkField15: false,
            checkField16: false,
            checkField17: false,
            checkField18: false,
            checkField19: false,
            checkField20: false,
            checkField21: false,
            checkField22: false,
            checkField23: false,
        }
        convertedData[data.result] = true;
        convertedData[data.itemInfo] = true;
        convertedData[data.isAppropriate] = true;
        convertedData[data.isReliable] = true;
        convertedData[data.isEnoughKnowledge] = true;
        setFormData(convertedData);
        if (isConfirm) {
            try {
                await postData(`/mst/meetingReview/`, {
                    ...convertedData, meeting_id: parseInt(meetingId)
                })
            } catch (e) {
                console.error(e)
            }
            navigate(`/buyer/meetings/${meetingId}/done`);
        } else {
            setIsConfirm(true)
        }
    }

    //===============================================
    // フォーム
    //===============================================
    //-----------------------------------------------
    // フォーム - スキーマ定義
    //-----------------------------------------------
    const selectErrMessage = "どれか一つ選択してください"
    const schema = yup.object().shape({
        result: yup.string().required('商談結果を入力してください'),
        textField01: yup.string().required('商談した商品名を入力してください'),
        itemInfo: yup.string().required(selectErrMessage),
        isAppropriate:yup.string().required(selectErrMessage),
        isReliable:yup.string().required(selectErrMessage),
        isEnoughKnowledge: yup.string().required(selectErrMessage),
        textField02: yup.string().required('その他の内容を入力してください'),
    })

    const useFormMethods = useForm({
        defaultValues: initialData,
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        resolver: yupResolver(schema),
    });
    const { control, handleSubmit, formState: { errors }, setValue, reset } = useFormMethods;

    //===============================================
    // CSS
    //===============================================
    const homeContainer = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
        backgroundColor: "#F0F0F1",
        padding: "0 10px",
        flexGrow: "1",
    };
    const homeInnerContainer = {
        flexDirection: "column",
        maxWidth: "800px",
        width: "100%",
        overflow: "hidden",
        alignItems: "center",
        justifyContent: "center",
    };
    const formContainer = {
        marginTop: "30px",
        paddingTop: "15px",
        paddingBottom: "20px",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        // padding: "0 20px",
    };
    const tableStyle = {
        borderCollapse: 'collapse',
        width: "90%",
        maxWidth: "550px",
    };

    const tableHeaderStyle = {
        border: '1px solid #909090',
        padding: '8px',
        textAlign: "start",
        fontWeight: "normal",
        backgroundColor: "white",
        width: "100px",
    };

    const tableCellStyle = {
        border: '1px solid #909090',
        padding: '8px',
        textAlign: "start",
        fontWeight: "normal",
        backgroundColor: "white",
        // minWidth: "200px",
    };

    const isBr = !isSmallScreen

    return (
        <div style={{ height: "100%" }}>
            <Header
                title={systemName}
                loginInfo={isSmallScreen ? "off" : "on"}
                backButton={isSmallScreen ? "off" : "on"}
            />
            <div style={{}}>
                <TitleBar title={title} />
            </div>
            <FormProvider  {...useFormMethods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div style={homeContainer}>
                        <div style={homeInnerContainer}>
                            <div
                                style={{
                                    backgroundColor: "#D5EDC7",
                                    padding: "15px",
                                    borderRadius: "10px",
                                    textAlign: "center",
                                    marginTop: "20px"
                                }}
                            >
                                オンライン商談が終わったらレビューを入力をして、 「入力情報の確認をする」 をクリックしてください。
                            </div>
                            <div className="form-container" style={formContainer}>
                                <div style={{ position: "relative" }}>
                                    <div
                                        style={{
                                            textAlign: "center",
                                            fontSize: "1.5em",
                                            margin: "0.5em",
                                            fontWeight: "bold",
                                            borderBottom: "1px solid",
                                        }}
                                    >
                                        オンライン商談レビュー投稿
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

                                    <table style={tableStyle}>
                                        <tbody>
                                            <tr>
                                                <th style={{ ...tableHeaderStyle, textAlign: "center", backgroundColor: "#F1F1F1" }}>番号</th>
                                                <td style={tableCellStyle}>{meetingData?.serialNo && `${meetingData.serialNo}`.padStart(3, "0")}</td>
                                            </tr>
                                            <tr>
                                                <th style={{ ...tableHeaderStyle, textAlign: "center", backgroundColor: "#F1F1F1" }}>日程調整日</th>
                                                <td style={{ ...tableCellStyle, backgroundColor: "white" }}>{meetingData?.adjustmentDate}</td>
                                            </tr>
                                            <tr>
                                                <th style={{ ...tableHeaderStyle, textAlign: "center", backgroundColor: "#F1F1F1" }}>出展者名</th>
                                                <td style={tableCellStyle}>{meetingData?.sellerName}</td>
                                            </tr>
                                            <tr>
                                                <th style={{ ...tableHeaderStyle, textAlign: "center", backgroundColor: "#F1F1F1" }}>商品名</th>
                                                <td style={tableCellStyle}>{meetingData?.itemName}</td>
                                            </tr>
                                            <tr>
                                                <th style={{ ...tableHeaderStyle, textAlign: "center", backgroundColor: "#F1F1F1" }}>商談日時</th>
                                                <td style={tableCellStyle}>{meetingData?.meetingDate}</td>
                                            </tr>
                                        </tbody>
                                    </table >

                                    {
                                        isConfirm ? <>
                                            <Stack spacing={2} style={{ maxWidth: "90%", margin: "1em 0" }} >
                                                <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start'>
                                                    <Typography sx={{ minWidth: '250px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>商談結果</Typography>
                                                    <Typography sx={{ mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{labels[0]}</Typography>
                                                </Stack>
                                                <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start'>
                                                    <Typography sx={{ minWidth: '250px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>商談した商品名</Typography>
                                                    <Typography sx={{ mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{formData?.textField01}</Typography>
                                                </Stack>
                                                <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start'>
                                                    <Typography sx={{ minWidth: '250px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>商談したかった商品の{isBr && <br />}情報は得られましたか?</Typography>
                                                    <Typography sx={{ mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{labels[1]}</Typography>
                                                </Stack>
                                                <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start'>
                                                    <Typography sx={{ minWidth: '250px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>的確な質問や回答を{isBr && <br />}していましたか?</Typography>
                                                    <Typography sx={{ mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{labels[2]}</Typography>
                                                </Stack>
                                                <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start'>
                                                    <Typography sx={{ minWidth: '250px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>担当者は信頼できそう{isBr && <br />}ですか?</Typography>
                                                    <Typography sx={{ mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{labels[3]}</Typography>
                                                </Stack>
                                                <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start'>
                                                    <Typography sx={{ minWidth: '250px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>担当者の商品に{isBr && <br />}対する知識は{isBr && <br />}十分でしたか?</Typography>
                                                    <Typography sx={{ mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{labels[4]}</Typography>
                                                </Stack>
                                                <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start'>
                                                    <Typography sx={{ minWidth: '250px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>その他</Typography>
                                                    <Typography sx={{ mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{formData?.textField02}</Typography>
                                                </Stack>
                                            </Stack></> :
                                            <Grid item xs={12} style={{ margin: "1em 0", maxWidth: "90%" }}>
                                                {/* 商談結果 */}
                                                <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} marginTop={1}>
                                                    <Grid item sx={{ width: '250px', textAlign: 'left' }}>
                                                        <FormLabel sx={{ whiteSpace: 'nowrap', border: '1px solid red', color: 'red', padding: '5px' }}>必須</FormLabel>
                                                        <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold' }}>商談結果</FormLabel>
                                                    </Grid>
                                                    <Grid item xs={isSmallScreen ? 12 : "auto"}>
                                                        <Stack direction={isSmallScreen ? "column" : "row"}>
                                                            <FormControl fullWidth>
                                                                <Controller
                                                                    name='result'
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <RadioGroup sx={{ color: '#666666', whiteSpace: 'nowrap' }}
                                                                            {...field}
                                                                            row={!isSmallScreen}
                                                                            onChange={(e) => {
                                                                                field.onChange(e);
                                                                            }}
                                                                        >
                                                                            <FormControlLabel value="checkField01" control={<Radio />} label="成立" />
                                                                            <FormControlLabel value="checkField02" control={<Radio />} label="不成立" />
                                                                            <FormControlLabel value="checkField03" control={<Radio />} label="保留・検討" />
                                                                        </RadioGroup>
                                                                    )}
                                                                />
                                                            </FormControl>
                                                        </Stack>
                                                        {errors.result && <div style={{ width: "100%", textAlign: "start", paddingLeft: "14px", color: '#d32f2f', fontSize: '0.8em' }}>{errors.result.message}</div>}
                                                    </Grid>
                                                </Grid>
                                                {/* 商品名 */}
                                                <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} >
                                                    <EntryCustomInput
                                                        id='textField01' name='textField01'
                                                        gideFlag
                                                        label='商談した商品名' labelWidth='250px' required='on' placeHolder="商品名を記載してください。"
                                                    />
                                                </Grid>
                                                {/* 情報は得られたか */}
                                                <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} marginTop={1}>
                                                    <Grid item sx={{ display: "flex", width: '250px', textAlign: 'left' }}>
                                                        <FormLabel sx={{ whiteSpace: 'nowrap', border: '1px solid red', color: 'red', padding: '5px', height: "31px" }}>必須</FormLabel>
                                                        <FormLabel sx={{ ml: 1, fontWeight: 'bold' }}> <div style={{ display: "inline" }}>商談したかった商品の<br />情報は得られましたか？</div> </FormLabel>
                                                    </Grid>
                                                    <Grid item xs={isSmallScreen ? 12 : "auto"}>
                                                        <Stack direction={isSmallScreen ? "column" : "row"}>
                                                            <FormControl fullWidth>
                                                                <Controller
                                                                    name='itemInfo'
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <RadioGroup sx={{ color: '#666666', whiteSpace: 'nowrap' }}
                                                                            {...field}
                                                                            row={!isSmallScreen}
                                                                            onChange={(e) => {
                                                                                field.onChange(e);
                                                                            }}
                                                                        >
                                                                            <FormControlLabel value="checkField04" control={<Radio />} label="満足" />
                                                                            <FormControlLabel value="checkField05" control={<Radio />} label="やや満足" />
                                                                            <FormControlLabel value="checkField06" control={<Radio />} label="普通" />
                                                                            <FormControlLabel value="checkField07" control={<Radio />} label="やや不満" />
                                                                            <FormControlLabel value="checkField08" control={<Radio />} label="不満" />
                                                                        </RadioGroup>
                                                                    )}
                                                                />
                                                            </FormControl>
                                                        </Stack>
                                                        {errors.itemInfo && <div style={{ width: "100%", textAlign: "start", paddingLeft: "14px", color: '#d32f2f', fontSize: '0.8em' }}>{errors.itemInfo.message}</div>}
                                                    </Grid>
                                                </Grid>
                                                {/* 的確な質問や回答をしていたか */}
                                                <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} marginTop={1}>
                                                    <Grid item sx={{ display: "flex", width: '250px', textAlign: 'left' }}>
                                                        <FormLabel sx={{ whiteSpace: 'nowrap', border: '1px solid red', color: 'red', padding: '5px', height: "31px" }}>必須</FormLabel>
                                                        <FormLabel sx={{ ml: 1, fontWeight: 'bold' }}> <div style={{ display: "inline" }}>的確な質問や回答を<br />していましたか？</div> </FormLabel>
                                                    </Grid>
                                                    <Grid item xs={isSmallScreen ? 12 : "auto"}>
                                                        <Stack direction={isSmallScreen ? "column" : "row"}>
                                                            <FormControl fullWidth>
                                                                <Controller
                                                                    name='isAppropriate'
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <RadioGroup sx={{ color: '#666666', whiteSpace: 'nowrap' }}
                                                                            {...field}
                                                                            row={!isSmallScreen}
                                                                            onChange={(e) => {
                                                                                field.onChange(e);
                                                                            }}
                                                                        >
                                                                            <FormControlLabel value="checkField09" control={<Radio />} label="満足" />
                                                                            <FormControlLabel value="checkField10" control={<Radio />} label="やや満足" />
                                                                            <FormControlLabel value="checkField11" control={<Radio />} label="普通" />
                                                                            <FormControlLabel value="checkField12" control={<Radio />} label="やや不満" />
                                                                            <FormControlLabel value="checkField13" control={<Radio />} label="不満" />
                                                                        </RadioGroup>
                                                                    )}
                                                                />
                                                            </FormControl>
                                                        </Stack>
                                                        <div>(一方的な案内等でコミュニケーションにならない、などはなかったですか。)</div>
                                                        {errors.isAppropriate && <div style={{ width: "100%", textAlign: "start", paddingLeft: "14px", color: '#d32f2f', fontSize: '0.8em' }}>{errors.isAppropriate.message}</div>}
                                                    </Grid>
                                                </Grid>
                                                {/* 担当者は信頼できそうか */}
                                                <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} marginTop={1}>
                                                    <Grid item sx={{ display: "flex", minWidth: '250px', textAlign: 'left' }}>
                                                        <FormLabel sx={{ whiteSpace: 'nowrap', border: '1px solid red', color: 'red', padding: '5px', height: "31px" }}>必須</FormLabel>
                                                        <FormLabel sx={{ ml: 1, fontWeight: 'bold', verticalAlign: "middle" }}> <div style={{ display: "inline" }}>担当者は信頼できそう{isBr && <br />}ですか？</div> </FormLabel>
                                                    </Grid>
                                                    <Grid item xs={isSmallScreen ? 12 : "auto"}>
                                                        <Stack direction={isSmallScreen ? "column" : "row"}>
                                                            <FormControl fullWidth>
                                                                <Controller
                                                                    name='isReliable'
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <RadioGroup sx={{ color: '#666666', whiteSpace: 'nowrap' }}
                                                                            {...field}
                                                                            row={!isSmallScreen}
                                                                            onChange={(e) => {
                                                                                field.onChange(e);
                                                                            }}
                                                                        >
                                                                            <FormControlLabel value="checkField14" control={<Radio />} label="満足" />
                                                                            <FormControlLabel value="checkField15" control={<Radio />} label="やや満足" />
                                                                            <FormControlLabel value="checkField16" control={<Radio />} label="普通" />
                                                                            <FormControlLabel value="checkField17" control={<Radio />} label="やや不満" />
                                                                            <FormControlLabel value="checkField18" control={<Radio />} label="不満" />
                                                                        </RadioGroup>
                                                                    )}
                                                                />
                                                            </FormControl>
                                                        </Stack>
                                                        <div style={{ textAlign: "start", wordBreak: "break-all" }}>(態度や振る舞いを含めて今後も継続して相談する場合、不安な点 {isBr && <br />}はなかったですか。)</div>
                                                        {errors.isReliable && <div style={{ width: "100%", textAlign: "start", paddingLeft: "14px", color: '#d32f2f', fontSize: '0.8em' }}>{errors.isReliable.message}</div>}
                                                    </Grid>
                                                </Grid>
                                                {/* 知識は十分か */}
                                                <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} marginTop={1}>
                                                    <Grid item sx={{ display: "flex", minWidth: '250px', textAlign: 'left' }}>
                                                        <FormLabel sx={{ whiteSpace: 'nowrap', border: '1px solid red', color: 'red', padding: '5px', height: "31px" }}>必須</FormLabel>
                                                        <FormLabel sx={{ ml: 1, fontWeight: 'bold' }}> <div style={{ display: "inline" }}>担当者の商品に{isBr && <br />}対する知識は十分{isBr && <br />}でしたか？</div> </FormLabel>
                                                    </Grid>
                                                    <Grid item xs={isSmallScreen ? 12 : "auto"}>
                                                        <Stack direction={isSmallScreen ? "column" : "row"}>
                                                            <FormControl fullWidth>
                                                                <Controller
                                                                    name='isEnoughKnowledge'
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <RadioGroup sx={{ color: '#666666', whiteSpace: 'nowrap' }}
                                                                            {...field}
                                                                            row={!isSmallScreen}
                                                                            onChange={(e) => {
                                                                                field.onChange(e);
                                                                            }}
                                                                        >
                                                                            <FormControlLabel value="checkField19" control={<Radio />} label="満足" />
                                                                            <FormControlLabel value="checkField20" control={<Radio />} label="やや満足" />
                                                                            <FormControlLabel value="checkField21" control={<Radio />} label="普通" />
                                                                            <FormControlLabel value="checkField22" control={<Radio />} label="やや不満" />
                                                                            <FormControlLabel value="checkField23" control={<Radio />} label="不満" />
                                                                        </RadioGroup>
                                                                    )}
                                                                />
                                                            </FormControl>
                                                        </Stack>
                                                        {errors.isEnoughKnowledge && <div style={{ width: "100%", textAlign: "start", paddingLeft: "14px", color: '#d32f2f', fontSize: '0.8em' }}>{errors.isEnoughKnowledge.message}</div>}
                                                    </Grid>
                                                </Grid>
                                                {/* その他 */}
                                                <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} marginTop={1}>
                                                    <EntryCustomField
                                                        hideHelperText
                                                        id='textField02' name='textField02'
                                                        label='その他' labelWidth='250px' required='on'
                                                        placeHolder="その他、お気づきの点や出展者の型へ伝えたいことがありましたらご記入ください。"
                                                    />
                                                </Grid>
                                            </Grid>
                                    }
                                </div>
                            </div>
                        </div>
                        <Grid item xs={12} sx={{ my: 3 }}>
                            <Button
                                onClick={() => {
                                    isConfirm ? setIsConfirm(false) : navigate(-1)
                                }}
                                variant="outlined"
                                sx={{ mr: 1 }}
                                size="large"
                                style={{
                                    color: "#4BBC06",
                                    fontSize: "20px",
                                    width: "300px",
                                    margin: "1rem",
                                }}
                            >
                                戻　る
                            </Button>
                            <Button
                                type="submit" variant="contained" sx={{ mr: 1 }} size="large"
                                style={{ color: '#fff', backgroundColor: '#4BBC06', fontSize: '20px', width: '300px', margin: '0.5rem' }}
                            >
                                {isConfirm ? "結果を報告する" : " 入力情報の確認をする"}
                            </Button>
                        </Grid>
                    </div>
                </form>
            </FormProvider>
            <Footer />
        </div >
    );
};

export default BuyerReviewPage;
