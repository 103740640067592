
import { useState, useEffect, useRef } from 'react'
import { Button, Stack, Typography } from '@mui/material'
import { jaJP } from '@mui/x-data-grid'
import Header from '../../components/Header'
import Frame from '../../components/uiParts/Frame'
import { useAuth } from './../../contexts/AuthContext'
import { useFetch } from './../../api/ApiRequests'
import TitleBar from '../../components/uiParts/TitleBar'
import ReviewModal from '../../components/modal/ReportModal'
import { CustomDataGrid } from '../../components/dataGrid/CustomDataGrid'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
const ReviewList = () => {

	const { authInfo, setAuthInfo } = useAuth()
	const [searchCount, setSearchCount] = useState(0)
	const [targetList, setTargetList] = useState({})
	const [meetingList, setMeetingList] = useState([])
	const [dataGridHeight, setDataGridHeight] = useState(window.innerHeight - 180)
	const [listType, setListType] = useState("all")
	const [openDialog, setOpenDialog] = useState(false)

	const effectRan = useRef(false)

	//===============================================
	// initialize
	//===============================================
	const systemName = process.env.REACT_APP_SYSTEM_NAME

	//-----------------------------------------------
	// initialize - セッション情報を取得
	//-----------------------------------------------
	const userId = authInfo.userId
	const userName = authInfo.userName

	//===============================================
	// API
	//===============================================
	//-----------------------------------------------
	// API - GETメソッドのテンプレート
	//-----------------------------------------------
	const getData = {
		userId: userId,
		userName: userName
	}
	//-----------------------------------------------
	// API - 商談一覧
	//-----------------------------------------------
	const { data: meetingData, loading: meetingLoading, fetchData: fetchMeetingData } = useFetch(getData)

	const combined = meetingData?.meeting?.map((m, idx) => [{
		// セラー側
		meeting_id: m.id,
		type: "seller",
		exhibit: m.exhibit,
		adjustment_date: m.adjustment_date,
		status: m.meeting_result ? "報告済み" : "未報告",
		display_number: `${m.display_number}`?.padStart(3, "0"),
		email: m.seller.email,
		name: m.seller.name,
		item: m.item,
		meeting_date: m.meeting_date,
		meeting_result: m.meeting_result,
		meeting_review: null,
	}, {
		// バイヤー側
		meeting_id: m.id,
		type: "buyer",
		exhibit: m.exhibit,
		adjustment_date: m.adjustment_date,
		status: m.meeting_review ? "報告済み" : "未報告",
		display_number: `${m.display_number}`?.padStart(3, "0"),
		email: m.buyer.email,
		name: m.buyer.name,
		item: m.item,
		meeting_date: m.meeting_date,
		meeting_result: null,
		meeting_review: m.meeting_review,
	}
	])?.flatMap(v => v).map((v, idx) => ({ ...v, id: idx })) || []

	const filtered = combined.filter(v =>
		listType === "all" ? v : listType === "seller" ? v.type === "seller" : v.type === "buyer"
	)?.sort((a, b) => a.display_number - b.display_number)

	//===============================================
	// useEffect
	//===============================================
	//-----------------------------------------------
	// useEffect - ロード時の設定
	//-----------------------------------------------
	useEffect(() => {
		if (effectRan.current === false) {
			effectRan.current = true

			setSearchCount(searchCount + 1)

			const handleResize = () => {
				setDataGridHeight(window.innerHeight - 180)
			}
			window.addEventListener('resize', handleResize)

			return () => {
				window.removeEventListener('resize', handleResize)
			}
		}
	}, [])

	useEffect(() => {
		fetchMeetingData(`/mst/meeting/`)
	}, [searchCount])


	useEffect(() => {
		if (meetingData) {
			setMeetingList(meetingData["meeting"])
		}
	}, [meetingData])

	const hasReview = targetList.type === "seller" ? !!targetList.meeting_result : !!targetList.meeting_review

	//-----------------------------------------------
	// eventHandler - ダイアログを非表示
	//-----------------------------------------------
	const handleClick_dialog_close = () => {
		setOpenDialog(false)
	}
	//===============================================
	// dataGird
	//===============================================
	//-----------------------------------------------
	// dataGird - テーブルの見出し
	//-----------------------------------------------
	const columns = [
		{ field: 'display_number', headerName: 'No.', width: 75, columnSeparatorWidth: 1 },
		{ field: 'status', headerName: 'ステータス', width: 100, columnSeparatorWidth: 1 },
		{ field: 'adjustment_date', headerName: '日程調整日', width: 125, columnSeparatorWidth: 1 },
		{ field: 'exhibit', headerName: '開催回', width: 75, columnSeparatorWidth: 1 },
		{ field: 'type', headerName: '出展者 / バイヤー', width: 150, columnSeparatorWidth: 1, valueGetter: (params) => params.row.type == "seller" ? "出展者" : "バイヤー" },
		{ field: 'email', headerName: 'メールアドレス', width: 250, columnSeparatorWidth: 1, },
		{ field: 'name', headerName: '出展者 / バイヤー名', width: 250, columnSeparatorWidth: 1, },
		{ field: 'item', headerName: '商品名', width: 250, columnSeparatorWidth: 1 },
		{ field: 'meeting_date', headerName: '商談日時', width: 250, columnSeparatorWidth: 1 },
	];

	//-----------------------------------------------
	// dataGrid - 行の選択
	//-----------------------------------------------
	/**選択された行と、現在のlistTypeから表示するオブジェクトの情報を修正 */
	const handleClick_dataGrid = (event) => {
		setTargetList(event.row)
		setOpenDialog(true)
	}
	//===============================================
	// CSS
	//===============================================
	const homeContainer = {
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#fff',
		padding: '1em',
		display: 'flex',
		flexDirection: 'column',
	}
	//===============================================
	// JSXのレンダー
	//===============================================
	return (
		<>
			<Header title={systemName} loginInfo="on" backButton="on" backPage="/home" />
			<div style={homeContainer}>
				<div style={{ width: "100%", maxWidth: "782px", backgroundColor: "#D5EDC7", padding: "15px", borderRadius: "10px", textAlign: "start" }}>
					現在投稿されているオンライン商談のレビュー一覧です。
				</div>
				<Stack direction={{ xs: "column", sm: "row" }} spacing={2} justifyContent="center" sx={{ width: "100%", maxWidth: "782px", my: 2 }}>
					<Button
						variant="outlined"
						color="primary"
						onClick={() => setListType("all")}
						sx={{
							width: "100%",
							borderRadius: 10,
							fontWeight: "bold",
							backgroundColor: listType === "all" ? "primary.main" : "transparent",
							color: listType === "all" ? "#fff" : "primary.main",
							"&:hover": {
								backgroundColor: listType === "all" ? "primary.dark" : "transparent",
							},
						}}
					>
						全レビュー一覧
					</Button>
					<Button
						variant="outlined"
						color="primary"
						onClick={() => setListType("seller")}
						sx={{
							width: "100%",
							borderRadius: 10,
							fontWeight: "bold",
							backgroundColor: listType === "seller" ? "primary.main" : "transparent",
							color: listType === "seller" ? "#fff" : "primary.main",
							"&:hover": {
								backgroundColor: listType === "seller" ? "primary.dark" : "transparent",
							},
						}}
					>
						出展者レビュー一覧
					</Button>
					<Button
						variant="outlined"
						color="primary"
						onClick={() => setListType("buyer")}
						sx={{
							width: "100%",
							borderRadius: 10,
							fontWeight: "bold",
							backgroundColor: listType === "buyer" ? "primary.main" : "transparent",
							color: listType === "buyer" ? "#fff" : "primary.main",
							"&:hover": {
								backgroundColor: listType === "buyer" ? "primary.dark" : "transparent",
							},
						}}
					>
						バイヤーレビュー一覧
					</Button>
				</Stack>
				<Frame className="frame" title={listType == "all" ? "全レビュー一覧" : listType == "seller" ? "出展者レビュー一覧" : "バイヤーレビュー一覧"}>
					<CustomDataGrid
						getRowClassName={(params) =>
							params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
						}
						editMode="row"
						rows={filtered}
						columns={columns}
						sx={{
							height: dataGridHeight,
							fontSize: 14,
							borderColor: 'primary.light'
						}}
						density="compact"
						localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
						showColumnRightBorder
						showCellRightBorder
						initialState={{ pagination: { paginationModel: { pageSize: 100 } }, }}
						pageSizeOptions={[50, 100]}
						loading={meetingLoading}
						onCellClick={(event) => handleClick_dataGrid(event)}
					/>
				</Frame>
			</div>
			{hasReview && <ReviewModal type={targetList.type} meeting={targetList} isDialogOpen={openDialog} onClose={handleClick_dialog_close} />}
		</>
	)
}
export default ReviewList
