import React, { useRef } from 'react';
import Button from '@mui/material/Button';

const PostButton = ({ onClick, ...props }) => {
// Refを使ってハンドラ処理中は次回clickを無視
  const isClicked = useRef(false);

  const handleClick = async (event) => {
    if (isClicked.current) {
      return;
    }

    isClicked.current = true;
    try {
        if (onClick) {
        await onClick(event);
      }
    } finally {
      isClicked.current = false;
    }
  };

  return (
    <Button {...props} onClick={handleClick} />
  );
};

export default PostButton;