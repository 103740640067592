import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import TitleBar from "../../components/uiParts/TitleBar";
import { Grid, useMediaQuery, useTheme, Button, FormLabel, Dialog, Box, DialogTitle, DialogContent, TableContainer, Table, TableBody, TableRow, TableCell, DialogActions, Paper } from "@mui/material";
import { useFetch } from "../../api/ApiRequests";
// import { apiURL } from '../../api/ApiConfig';
import { cityList } from "../../Store";
import { usePost } from "../../api/ApiRequests";
import { useAuth } from "../../contexts/AuthContext";

const SellerMeetingsPage = ({ formData, setformData }) => {
  const { authInfo, setAuthInfo } = useAuth();
  const navigate = useNavigate();
  const { data: mettingData, fetchData: fetchMettingData } = useFetch();
  const { data: timerexUrlData, fetchData: fetchTimerexUrlData } = useFetch();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  //===============================================
  // initialize
  //===============================================
  const systemName = process.env.REACT_APP_SYSTEM_NAME;

  //-----------------------------------------------
  // initialize - セッション情報を取得
  //-----------------------------------------------
  const userId = authInfo.userId;
  const userName = authInfo.userName;
  const authority = authInfo.authority;

  const effectRan = useRef(false);

  const title = `商談状況`;

  const item = cityList.find((item) => item.code == formData?.city);
  const cityName = item?.label || "";

  //===============================================
  // API
  //===============================================
  useEffect(() => {
    fetchMettingData("/mst/seller/meeting");
    fetchTimerexUrlData(`/mst/seller/timerex/?seller_id=${authInfo.sellerId}`);
  }, []);

  //-----------------------------------------------
  // API - POSTメソッドのテンプレート
  //-----------------------------------------------
  const { postData } = usePost({
    userId: userId,
    userName: userName,
  });

  const {
    data: sellerProfile,
    loading: seller_Loading,
    fetchData: fetchSellerData,
  } = useFetch({
    userId: userId,
    userName: userName,
  });

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedMeetingIndex, setSelectedMeetingIndex] = useState(0);
  //===============================================
  // useEffect
  //===============================================
  useEffect(() => {
    // window.scrollTo(0, 0);
  }, []);

  const initializedRef = useRef(false);
  useEffect(() => {
    if (!initializedRef.current) {
      const scriptEl = document.createElement("script");
      scriptEl.id = "timerex-script";
      scriptEl.src = "https://timerex.net/js/embed.js";
      scriptEl.type = "text/javascript";
      document.body.appendChild(scriptEl);
      scriptEl.onload = () => {
        if (window.TimerexCalendar && !initializedRef.current) {
          window.TimerexCalendar();
          initializedRef.current = true; // 初期化済みとする
        }
      };
      return () => {
        document.body.removeChild(scriptEl);
      };
    }
  }, []);

  //===============================================
  // eventHandler
  //===============================================

  const onClickReport = (index) => {
    setSelectedMeetingIndex(index)
    setIsDialogOpen(true)
  }

  //===============================================
  // CSS
  //===============================================
  const pageContainer = {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  };

  const bodyContainer = {
    flexGrow: "1",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0 10px",
    backgroundColor: "#F0F0F1",
  };

  const contentContainer = {
    flexDirection: "column",
    maxWidth: "800px",
    width: "100%",
    overflow: "auto",
  };

  const tableStyle = {
    borderCollapse: "collapse",
    width: "800px",
  };

  const tableThStyle = {
    border: "1px solid #868686",
    backgroundColor: "#F1F1F1",
    lineHeight: "1.6em",
  };

  const tableTdStyle = {
    border: "1px solid #868686",
    backgroundColor: "white",
    lineHeight: "1.6em",
    textAlign: "left",
    paddingLeft: "8px",
  };

  const innerTableStyle = {
    marginTop: "20px",
    borderCollapse: 'collapse',
    width: "100%",
    maxWidth: "550px",
    lineHeight: "1em",
  };

  const innerTableHeaderStyle = {
    border: '1px solid #909090',
    padding: '8px',
    textAlign: "start",
    fontWeight: "normal",
    backgroundColor: "white",
    width: "100px",
  };

  const innerTableCellStyle = {
    border: '1px solid #909090',
    padding: '8px',
    textAlign: "start",
    fontWeight: "normal",
    backgroundColor: "white",
  };

  function formatDatetimeRange(startDatetime, endDatetime) {
    const start = new Date(startDatetime);
    const end = new Date(endDatetime);

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}`;
    };

    const formattedStart = formatDate(start);
    const formattedEnd = formatDate(end);

    return `${formattedStart.split(" ")[0]} ${formattedStart.split(" ")[1]}-${formattedEnd.split(" ")[1]}`;
  }

  const selectedMeeting = mettingData?.meeting?.[selectedMeetingIndex]
  const resultText = selectedMeeting?.result?.checkField01 ? "成立" : selectedMeeting?.result?.checkField02 ? "不成立" : "保留 ・ 検討"

  return (
    <div style={pageContainer}>
      <Header title={systemName} loginInfo={isSmallScreen ? "off" : "on"} backButton={isSmallScreen ? "off" : "on"} />
      <div style={{}}>
        <TitleBar title={title} />
      </div>
      <div style={bodyContainer}>
        <div style={contentContainer}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#D5EDC7",
              padding: "15px",
              margin: "30px 0",
              borderRadius: "10px",
              textAlign: "start",
            }}
          >
            商談日時になったらメールで配信された日程調整完了のメールのリンク、または下記の「商談に参加」 ボタンからオンライン商談を開始してください。
          </div>
          <div style={{ overflow: "auto" }}>
            <table style={tableStyle}>
              <thead>
                <th style={tableThStyle}>番号</th>
                <th style={tableThStyle}>会社名</th>
                <th style={tableThStyle}>名前</th>
                <th style={tableThStyle}>商談日時</th>
                <th style={tableThStyle}>URL</th>
                <th style={tableThStyle}>結果報告</th>
              </thead>
              <tbody>
                {mettingData &&
                  mettingData.meeting.map((data, index) => (
                    <tr>
                      <td
                        style={{
                          ...tableTdStyle,
                          textAlign: "center",
                          paddingLeft: 0,
                        }}
                      >
                        {String(data.serial_no).padStart(3, "0")}
                      </td>
                      <td style={tableTdStyle}>{data.company_name}</td>
                      <td style={tableTdStyle}>{data.item_name}</td>

                      <td style={tableTdStyle}>{data.meeting_time}</td>
                      <td style={{
                        ...tableTdStyle,
                        display: "flex",
                        justifyContent: "center",
                        padding: "5px 0"
                      }}>
                        <Button
                          href={data.meeting_url}
                          target="_blank"
                          variant="contained" size="small"
                          style={{ color: '#fff', backgroundColor: '#4BBC06', fontSize: '20px', width: '120px' }}
                        >
                          商談の開始</Button>
                      </td>
                      <td style={{ ...tableTdStyle, width: "80px" }}>

                        <Button
                          variant="contained"
                          style={{
                            padding: 0,
                            margin: "0 6px 2px 0",
                            backgroundColor: data.result === null ? "#4BBC06" : "#808080",
                          }}
                          onClick={() => {
                            data.result === null ? navigate(`${data.id}/report`) : onClickReport(index)
                          }}
                        >
                          {
                            data.result === null ? "報告する" : "報告済"
                          }
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div
            style={{
              width: "100%",
              backgroundColor: "#D5EDC7",
              padding: "15px",
              marginBottom: "30px",
              marginTop: "30px",
              borderRadius: "10px",
            }}
          >
            <div style={{ textAlign: "start", margin: "10px" }}>
              面談時間は期間中の 9 時~ 17 時の間に設定されています。 <br />
              期間中に面談対応 「不可」 の時間帯がありましたら、 <br />
              「Outlook のカレンダーで入力」 をクリックして Outlook カレンダーにダミーの予定を入力してください。
            </div>
            <a href="" style={{ fontWeight: "bold", color: "#007D50" }} onClick={() => window.open(`${process.env.PUBLIC_URL}/images/dummy_schedule.pdf`, "_blank")}>
              ダミーの予定の入力方法はこちら
            </a>
            <div style={{ margin: "10px 0" }}>
              <Button
                variant="contained"
                style={{
                  fontSize: "18px",
                  padding: "10px 50px",
                  backgroundColor: "#0071BC",
                }}
              >
                <a style={{ color: "white", textDecoration: "none", textTransform: "none" }} href={timerexUrlData?.outlook_calendar_url} target="_blank" rel="noreferrer">
                  Outlookのカレンダーで入力
                </a>
              </Button>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              padding: "15px",
              marginTop: "20px",
              borderRadius: "10px",
              textAlign: "start",
              backgroundColor: "#D5EDC7",
              marginBottom: "30px",
            }}
          >
            注意点：下記のカレンダーはバイヤーから見た出展者に対しての商談申し込み用の日程候補の見え方の参考画面です。出展者は操作ができませんのでご注意ください。<br />
            バイヤーが出展者の申し込み用のカレンダーにアクセスした場合、表示される期間が自動で更新されます。 <br />
            （例：商談日程開始日が10月1日の日程調整カレンダーに10月10日にバイヤーがアクセスした場合、表示される日程候補は10月10日以降です。
          </div>
          {timerexUrlData && (
            <div style={{ pointerEvents: "none" }}>
              <div id="timerex_calendar" data-url={timerexUrlData.timerex_url}></div>
            </div>
          )}
          <Button
            onClick={() => navigate(-1)}
            variant="outlined"
            sx={{ mr: 1 }}
            size="large"
            style={{
              color: "#4BBC06",
              fontSize: "20px",
              width: "300px",
              margin: "20px 0",
            }}
          >
            戻　る
          </Button>

        </div>
      </div>
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <Box sx={{ width: "500px", p: 2 }}>
          <DialogTitle sx={{
            width: "100%",
            background: "rgb(0, 125, 80)",
            borderRadius: "5px",
            color: "white",
            paddingLeft: "2rem",
            textAlign: "left",
            paddingTop: "5px",
            paddingBottom: "5px",
          }}>商談結果報告</DialogTitle>
          <DialogContent sx={{ mt: 2, backgroundColor: "#f2f2f2" }}>
            <table style={innerTableStyle}>
              <tbody>
                <tr>
                  <th style={{ ...innerTableHeaderStyle, textAlign: "center", backgroundColor: "#F1F1F1" }}>番号</th>
                  <td style={innerTableCellStyle}>{`${selectedMeeting?.serial_no}`?.padStart(3, "0")}</td>
                </tr>
                <tr>
                  <th style={{ ...innerTableHeaderStyle, textAlign: "center", backgroundColor: "#F1F1F1" }}>日程調整日</th>
                  <td style={{ ...innerTableCellStyle, backgroundColor: "white" }}>{selectedMeeting?.adjustment_date}</td>
                </tr>
                <tr>
                  <th style={{ ...innerTableHeaderStyle, textAlign: "center", backgroundColor: "#F1F1F1" }}>出展者名</th>
                  <td style={{ ...innerTableCellStyle, backgroundColor: "white" }}>{selectedMeeting?.seller_name}</td>
                </tr>
                <tr>
                  <th style={{ ...innerTableHeaderStyle, textAlign: "center", backgroundColor: "#F1F1F1" }}>商品名</th>
                  <td style={innerTableCellStyle}>{selectedMeeting?.item_name}</td>
                </tr>
                <tr>
                  <th style={{ ...innerTableHeaderStyle, textAlign: "center", backgroundColor: "#F1F1F1" }}>商談日時</th>
                  <td style={innerTableCellStyle}>{selectedMeeting?.meeting_time}</td>
                </tr>
              </tbody>
            </table >

            <TableContainer component={Paper} sx={{ py: 2, boxShadow: 'none', backgroundColor: 'transparent' }}>
              <Table sx={{ borderCollapse: 'collapse' }}>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ py: 1, borderBottom: 'none', fontWeight: "bold", wordBreak: "keep-all" }} component="th" scope="row">商談結果</TableCell>
                    <TableCell sx={{ py: 1, borderBottom: 'none' }}>{resultText}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ py: 1, borderBottom: 'none', fontWeight: "bold", wordBreak: "keep-all" }} component="th" scope="row">商談した商品名</TableCell>
                    <TableCell sx={{ py: 1, borderBottom: 'none' }}>{selectedMeeting?.result?.textField01}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ py: 1, borderBottom: 'none', fontWeight: "bold", wordBreak: "keep-all" }} component="th" scope="row">商談結果の内容</TableCell>
                    <TableCell sx={{ py: 1, borderBottom: 'none' }}>{selectedMeeting?.result?.textField02}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions sx={{ mt: 2, justifyContent: "center" }}>
            <Button
              onClick={() => setIsDialogOpen(false)}
              variant="contained" size="small"
              olor="primary"
              style={{ color: '#fff', backgroundColor: '#757575', fontSize: '20px', width: '120px' }}
            >
              閉じる
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Footer />
    </div>
  );
};

export default SellerMeetingsPage;
