import { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import TitleBar from '../../components/uiParts/TitleBar';
import { Grid, useMediaQuery, useTheme, Button, FormLabel } from '@mui/material'
import { apiURL } from '../../api/ApiConfig';
import { itemCategoryList } from '../../Store'
import { usePost, useFetch } from '../../api/ApiRequests';
import { useAuth } from '../../contexts/AuthContext'
import { cityList } from '../../Store'
import { addDays, format } from 'date-fns'
import PostButton from '../../components/PostButton';

const SellerConfirmNewItemPage = ({ formData, setFormData }) => {

    const { authInfo } = useAuth()
    const [mailto, setMailTo] = useState('')
    const navigate = useNavigate()

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const search = useLocation().search
    const query = new URLSearchParams(search)
    const sellerId = query.get('sellerId')

    //===============================================
    // initialize
    //===============================================
    const today = format(addDays(new Date(), 0), 'yyyy-MM-dd')
    const systemName = process.env.REACT_APP_SYSTEM_NAME

    //-----------------------------------------------
    // initialize - セッション情報を取得
    //-----------------------------------------------
    const userId = authInfo.userId
    const userName = authInfo.userName
    const authority = authInfo.authority

    const effectRan = useRef(false)

    const item = itemCategoryList.find(item => item.code == formData.itemCategory)
    const itemCategoryName = item?.label

    //===============================================
    // API
    //===============================================
    //-----------------------------------------------
    // API - GETメソッドのテンプレート
    //-----------------------------------------------
    const getData = {
        userId: userId,
        userName: userName
    }
    //-----------------------------------------------
    // API - セラーマスタ
    //-----------------------------------------------
    const { data: sellerData, loading: seller_Loading, fetchData: fetchSellerData } = useFetch(getData)

    //===============================================
    // useEffect
    //===============================================
    //-----------------------------------------------
    // useEffect - 起動時の処理
    //-----------------------------------------------
    useEffect(() => {
        // 初回マウント時のみ実行
        if (effectRan.current === false) {
            fetchSellerData(`/mst/seller/${formData.seller}`)
            window.scrollTo(0, 0);
        }
        return () => {
            effectRan.current = true;
        }
    }, [])
    //-----------------------------------------------
    // useEffect - セラーマスタ
    //-----------------------------------------------
    useEffect(() => {
        if (sellerData) {
            setMailTo(sellerData.eMail)
        }
    }, [sellerData])

    //===============================================
    // API
    //===============================================
    //-----------------------------------------------
    // API - POSTメソッドのテンプレート
    //-----------------------------------------------
    const { postData } = usePost({
        userId: userId,
        userName: userName
    })
    //===============================================
    // eventHandler
    //===============================================
    //-----------------------------------------------
    // eventHandler - 登録処理の実行
    //-----------------------------------------------
    const handleConfirm = async () => {
        try {
            const onSuccess = (data) => {
                //send_mail('/itemForm?sellerId='+sellerId)
                navigate('/seller/items/new/done?sellerId=' + authInfo.sellerId)
            }
            await postData('/mst/item/', formData, onSuccess)
        } catch (error) {
            console.error(error);
        }
    }
    //-----------------------------------------------
    // eventHandler - 登録処理を実行して完了画面に移動する
    //-----------------------------------------------
    const handleClick_complete = async () => {
        // try {
        //     const onSuccess = (data) => {
        //         console.log('Extracted data:', data); // ここで確認のためにログ出力
        //         //navigate('/complete')
        //         send_mail('/complete')
        //     }
        //     await postData('/mst/item/', formData, onSuccess);
        // } catch (error) {
        //     console.error(error);
        // }
    }
    //-----------------------------------------------
    // eventHandler - フォーム画面に戻る
    //-----------------------------------------------
    const handleClick_entry = () => {
        navigate('/itemForm?sellerId=' + sellerId)
    }
    //-----------------------------------------------
    // eventHandler - 受付メールを送信する
    //-----------------------------------------------
    const send_mail = (nextPage) => {

        const title = '【北陸4県応援企画・食のオンライン商談会2024】出展のお申込ありがとうございます'
        let mailText = `ご担当者様\n\n`
        mailText = mailText + `北陸4県応援企画・食のオンライン商談会2024へのお申込ありがとうございました。\n`
        mailText = mailText + `事務局の審査が完了しましたら、事務局からご連絡いたします。\n\n`
        mailText = mailText + `※送信元のメールアドレスは送信専用となっております。\n`
        mailText = mailText + `返信いただいても、ご回答できませんのであらかじめご了承願います。\n\n`

        const sendData = {
            subject: title,
            message: mailText,
            recipient_list: [mailto],
            cc_list: []
        }
        const submitDataToPost = async () => {
            try {
                await postData('/mst/send_email/', sendData)
            } catch (error) {
                console.error('Error in POST request:', error)
            }
        }
        submitDataToPost()
        // 後処理
        try {
            navigate(nextPage)
        } catch (error) {
            console.error('Error in update function:', error);
        }
    }
    //===============================================
    // CSS
    //===============================================
    const homeContainer = {
        // width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '360px', // フッターの高さ分だけ余白を追加
        backgroundColor: '#F0F0F1',
        padding: "0 10px"
    }
    const homeInnerContainer = {
        width: "100%",
        maxWidth: "800px",
        marginTop: "10px",
    }
    const responsiveImage = {
        width: '380px',
    }
    const formContainer = {
        marginTop: "30px",
        borderRadius: "10px",
        padding: "0 20px",
        backgroundColor: "white",
        display: 'flex',
        flexDirection: "column",
    }
    return (
        <>
            <Header title={systemName} loginInfo={isSmallScreen ? "off" : "on"} backButton={isSmallScreen ? "off" : "on"} />
            <div style={{}}>
                <TitleBar title="商品ページ" />
            </div>
            <div style={homeContainer}>

                <div style={homeInnerContainer}>
                    <div style={{ textAlign: "start", fontWeight: "bold", marginBottom: "10px" }}>商品ページ状態：承認待ち</div>
                    <div style={{ backgroundColor: "#D5EDC7", padding: "15px", borderRadius: "10px", textAlign: "start" }}>
                        編集した内容を確認して、 「承認申請」 ボタンをクリックしてください。 <br />
                        事務局より承認されるとサイトに反映されます。
                    </div>

                    <div className="form-container" style={formContainer}>
                        <div style={{ fontSize: '1.5em', marginTop: '0.5em', fontWeight: 'bold' }}>商品情報</div>
                        {/* 以下繰り返し */}
                        <div style={{ fontSize: '1.5em', marginTop: '0.5em', fontWeight: 'bold', borderBottom: "1px solid" }}></div>

                        <Grid
                            container
                            direction="column"
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                            required='on'
                            marginBottom={"20px"}
                        >
                            <Grid item xs={12}>
                                <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                    <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>商品名</FormLabel>
                                    </Grid>
                                    <Grid item xs={isSmallScreen ? 12 : 'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em', marginLeft: '1em' }}>{formData.name}</FormLabel>
                                    </Grid>
                                </Grid>
                                <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                    <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>商品名（フリガナ）</FormLabel>
                                    </Grid>
                                    <Grid item xs={isSmallScreen ? 12 : 'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em', marginLeft: '1em' }}>{formData.nameKana}</FormLabel>
                                    </Grid>
                                </Grid>
                                <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                    <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>商品カテゴリ</FormLabel>
                                    </Grid>
                                    <Grid item xs={isSmallScreen ? 12 : 'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em', marginLeft: '1em' }}>{itemCategoryName}</FormLabel>
                                    </Grid>
                                </Grid>
                                <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                    <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>キャッチフレーズ</FormLabel>
                                    </Grid>
                                    <Grid item xs={isSmallScreen ? 12 : 'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em' }}>{formData.textField01}</FormLabel>
                                    </Grid>
                                </Grid>
                                <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                    <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>商品説明</FormLabel>
                                    </Grid>
                                    <Grid item xs={isSmallScreen ? 12 : 'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em' }}>{formData.textField02}</FormLabel>
                                    </Grid>
                                </Grid>
                                <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                    <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>商品画像</FormLabel>
                                    </Grid>
                                    <Grid item xs={isSmallScreen ? 12 : 'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                        <img src={`${apiURL}media/uploads/${formData.imageField01}`} alt="Example" style={responsiveImage} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    <Grid item xs={12} sx={{ my: 3 }}>
                        <Button
                            onClick={() => navigate(-1)}
                            variant="outlined" sx={{ mr: 1 }} size="large"
                            style={{ color: '#4BBC06', fontSize: '20px', width: '300px', margin: '0.5rem', }}
                        >
                            戻　る
                        </Button>
                        <PostButton
                            onClick={() => handleConfirm()} variant="contained" sx={{ mr: 1 }} size="large"
                            style={{ color: '#fff', backgroundColor: '#4BBC06', fontSize: '20px', width: '300px', margin: '0.5rem' }}
                        >
                            承認申請
                        </PostButton>
                    </Grid>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default SellerConfirmNewItemPage;