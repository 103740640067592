import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import TitleBar from '../../components/uiParts/TitleBar';
import { Grid, useMediaQuery, useTheme, Button } from '@mui/material'

const SellerDoneReportPage = () => {

    const navigate = useNavigate()

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

    //===============================================
    // initialize
    //===============================================
    const systemName = process.env.REACT_APP_SYSTEM_NAME

    const homeContainer = {
        flexGrow: "1",
        display: "flex",
        flexDirection: "column",
        alignItems: 'center',
        paddingBottom: '360px', // フッターの高さ分だけ余白を追加
        backgroundColor: '#F0F0F1',
        padding: "0 10px"
    }
    const homeInnerContainer = {
        width: "100%",
        maxWidth: "800px",
        marginTop: "10px",
    }
    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <Header title={systemName} loginInfo={isSmallScreen ? "off" : "on"} backButton={isSmallScreen ? "off" : "on"} />
            <div >
                <TitleBar title="商品ページ" />
            </div>
            <div style={homeContainer}>
                <div style={homeInnerContainer}>
                    <div style={{ backgroundColor: "#D5EDC7", padding: "15px", borderRadius: "10px", textAlign: "start", marginTop: "15px" }}>
                        オンライン商談結果報告が事務局へ送信されました。 ありがとうございました。
                    </div>
                    <Grid item xs={12} sx={{ my: 3 }}>
                        <Button
                            onClick={() => navigate("/seller")}
                            variant="outlined" sx={{ mr: 1 }} size="large"
                            style={{ color: '#4BBC06', fontSize: '20px', width: '300px', margin: '0.5rem', }}
                        >
                            ホームへ戻る
                        </Button>
                    </Grid>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default SellerDoneReportPage;