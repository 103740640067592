import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material"
import { getReviewLabel } from "../../utils/getReviewLabel";

const ReviewModal = ({ type, meeting, isDialogOpen, onClose }) => {

    const innerTableStyle = {
        marginTop: "20px",
        borderCollapse: 'collapse',
        width: "100%",
        maxWidth: "550px",
        lineHeight: "1em",
    };

    const innerTableHeaderStyle = {
        border: '1px solid #909090',
        padding: '8px',
        textAlign: "start",
        fontWeight: "normal",
        backgroundColor: "white",
        width: "100px",
    };

    const innerTableCellStyle = {
        border: '1px solid #909090',
        padding: '8px',
        textAlign: "start",
        fontWeight: "normal",
        backgroundColor: "white",
    };


    const resultFields = type === "seller" ? meeting?.meeting_result : meeting?.meeting_review
    const resultText = resultFields?.checkField01 ? "成立" : resultFields?.checkField02 ? "不成立" : "保留 ・ 検討"
    const reviewLabels = meeting?.meeting_review && Object.entries(meeting.meeting_review).filter(([k, v]) => v === true).map(v => getReviewLabel(v[0]))

    return <Dialog open={isDialogOpen} onClose={() => onClose()}>
        <Box sx={{ width: "500px", p: 2 }}>
            <DialogTitle sx={{
                width: "100%",
                background: "rgb(0, 125, 80)",
                borderRadius: "5px",
                color: "white",
                paddingLeft: "2rem",
                textAlign: "left",
                paddingTop: "5px",
                paddingBottom: "5px",
            }}>{type === "seller" ? "報告内容" : "レビュー内容"}</DialogTitle>
            <DialogContent sx={{ mt: 2, backgroundColor: "#f2f2f2" }}>
                <table style={innerTableStyle}>
                    <tbody>
                        <tr>
                            <th style={{ ...innerTableHeaderStyle, textAlign: "center", backgroundColor: "#F1F1F1" }}>番号</th>
                            <td style={innerTableCellStyle}>{`${meeting?.meeting_id}`?.padStart(3, "0")}</td>
                        </tr>
                        <tr>
                            <th style={{ ...innerTableHeaderStyle, textAlign: "center", backgroundColor: "#F1F1F1" }}>日程調整日</th>
                            <td style={{ ...innerTableCellStyle, backgroundColor: "white" }}>{meeting?.adjustment_date}</td>
                        </tr>
                        <tr>
                            <th style={{ ...innerTableHeaderStyle, textAlign: "center", backgroundColor: "#F1F1F1" }}>出展者名</th>
                            <td style={{ ...innerTableCellStyle, backgroundColor: "white" }}>{meeting?.name}</td>
                        </tr>
                        <tr>
                            <th style={{ ...innerTableHeaderStyle, textAlign: "center", backgroundColor: "#F1F1F1" }}>商品名</th>
                            <td style={innerTableCellStyle}>{meeting?.item}</td>
                        </tr>
                        <tr>
                            <th style={{ ...innerTableHeaderStyle, textAlign: "center", backgroundColor: "#F1F1F1" }}>商談日時</th>
                            <td style={innerTableCellStyle}>{meeting?.meeting_date}</td>
                        </tr>
                    </tbody>
                </table >

                {
                    type === "seller" ?
                        <TableContainer component={Paper} sx={{ py: 2, boxShadow: 'none', backgroundColor: 'transparent' }}>
                            <Table sx={{ borderCollapse: 'collapse' }}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{ py: 1, borderBottom: 'none', fontWeight: "bold", wordBreak: "keep-all" }} component="th" scope="row">商談結果</TableCell>
                                        <TableCell sx={{ py: 1, borderBottom: 'none' }}>{resultText}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ py: 1, borderBottom: 'none', fontWeight: "bold", wordBreak: "keep-all" }} component="th" scope="row">商談した商品名</TableCell>
                                        <TableCell sx={{ py: 1, borderBottom: 'none' }}>{meeting?.meeting_result?.textField01}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ py: 1, borderBottom: 'none', fontWeight: "bold", wordBreak: "keep-all" }} component="th" scope="row">商談結果の内容</TableCell>
                                        <TableCell sx={{ py: 1, borderBottom: 'none' }}>{meeting?.meeting_result?.textField02}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer> : <TableContainer component={Paper} sx={{ py: 2, boxShadow: 'none', backgroundColor: 'transparent' }}>
                            <Table sx={{ borderCollapse: 'collapse' }}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{ py: 1, borderBottom: 'none', fontWeight: "bold", wordBreak: "keep-all" }} component="th" scope="row">商談結果</TableCell>
                                        <TableCell sx={{ py: 1, borderBottom: 'none' }}>{reviewLabels?.[0]}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ py: 1, borderBottom: 'none', fontWeight: "bold", wordBreak: "keep-all" }} component="th" scope="row">商談した商品名</TableCell>
                                        <TableCell sx={{ py: 1, borderBottom: 'none' }}>{meeting?.meeting_review?.textField01}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ py: 1, borderBottom: 'none', fontWeight: "bold", wordBreak: "keep-all" }} component="th" scope="row">商談したかった商品の<br />情報は得られましたか？ </TableCell>
                                        <TableCell sx={{ py: 1, borderBottom: 'none' }}>{reviewLabels?.[1]}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ py: 1, borderBottom: 'none', fontWeight: "bold", wordBreak: "keep-all" }} component="th" scope="row">的確な質問や解答を<br />していましたか？</TableCell>
                                        <TableCell sx={{ py: 1, borderBottom: 'none' }}>{reviewLabels?.[2]}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ py: 1, borderBottom: 'none', fontWeight: "bold", wordBreak: "keep-all" }} component="th" scope="row">担当者は信頼できそう<br />ですか？</TableCell>
                                        <TableCell sx={{ py: 1, borderBottom: 'none' }}>{reviewLabels?.[3]}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ py: 1, borderBottom: 'none', fontWeight: "bold", wordBreak: "keep-all" }} component="th" scope="row">担当者の商品に<br />対する知識は十分<br />でしたか？</TableCell>
                                        <TableCell sx={{ py: 1, borderBottom: 'none' }}>{reviewLabels?.[4]}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ py: 1, borderBottom: 'none', fontWeight: "bold", wordBreak: "keep-all" }} component="th" scope="row">その他</TableCell>
                                        <TableCell sx={{ py: 1, borderBottom: 'none' }}>{meeting?.meeting_review?.textField02}</TableCell>
                                    </TableRow>

                                </TableBody>
                            </Table>
                        </TableContainer>
                }
            </DialogContent>
            <DialogActions sx={{ mt: 2, justifyContent: "center" }}>
                <Button
                    onClick={() => onClose()}
                    variant="contained" size="small"
                    olor="primary"
                    style={{ color: '#fff', backgroundColor: '#757575', fontSize: '20px', width: '120px' }}
                >
                    閉じる
                </Button>
            </DialogActions>
        </Box>
    </Dialog>
}

export default ReviewModal;